:export {
  fs-55: .55rem;
  fs-54: .54rem;
  fs-52: .52rem;
  fs-50: .5rem;
  fs-48: .48rem;
  fs-46: .46rem;
  fs-44: .44rem;
  fs-42: .42rem;
  fs-40: .4rem;
  fs-38: .38rem;
  fs-36: .36rem;
  fs-34: .34rem;
  fs-32: .32rem;
  fs-30: .3rem;
  fs-28: .28rem;
  fs-26: .26rem;
  fs-24: .24rem;
  fs-22: .22rem;
  fs-20: .2rem;
  fs-18: .18rem;
  fs-16: .16rem;
  fs-14: .14rem;
  fs-12: .12rem;
  fs-10: .1rem;
  light-themes1: #96f;
  light-themes2: #00377a;
  light-echarts1: #fe2c55;
  light-echarts2: #60a9ff;
  light-echarts3: #5ec45e;
  light-echarts4: #32b5b5;
  light-echarts5: #faad14;
  light-echarts6: #fe8358;
  light-echarts7: #9760e3;
  light-link: #96f;
  light-tb-head: rgba(61,150,255,0.08);
  light-btn-color: #fff;
  light-source: #6c849c;
  light-border: rgba(50,58,70,0.1);
  light-placeholder: rgba(50,58,70,0.25);
  light-inp-border: #dcdfe6;
  light-th-border: #b9d0ea;
  light-desc: #707070;
  light-color: rgba(24,25,31,0.9);
  light-title: #18191f;
  light-card-bg: #fff;
  light-popup-bg: #fff;
  light-bg: #fff;
  dark-themes1: #4793cf;
  dark-themes2: #7a8a9c;
  dark-echarts1: #fe2c55;
  dark-echarts2: #60a9ff;
  dark-echarts3: #5ec45e;
  dark-echarts4: #32b5b5;
  dark-echarts5: #faad14;
  dark-echarts6: #fe8358;
  dark-echarts7: #9760e3;
  dark-link: #378bee;
  dark-tb-head: rgba(61,150,255,0.08);
  dark-btn-color: rgba(255,255,255,0.9);
  dark-source: #6c849c;
  dark-border: #111c2d;
  dark-placeholder: rgba(255,255,255,0.25);
  dark-inp-border: #142033;
  dark-th-border: rgba(71,147,207,0.25);
  dark-desc: rgba(255,255,255,0.65);
  dark-color: rgba(255,255,255,0.85);
  dark-title: #fff;
  dark-card-bg: #212d40;
  dark-popup-bg: #222f43;
  dark-bg: #111925;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  content: "";
}

.clearfix {
  zoom: 1;
  clear: both;
}

.no-info {
  width: 100%;
  text-align: center;
  font-size: 0.12rem;
  padding: 0.5rem 0;
}

[data-theme='light'] .no-info {
  color: #707070;
}

[data-theme='dark'] .no-info {
  color: rgba(255,255,255,0.65);
}

.tit-cnt-box .tc-title,
.tit-cnt-box .tc-cnt {
  display: inline-block;
  vertical-align: top;
}

.tit-cnt-box .tc-title.center,
.tit-cnt-box .tc-cnt.center {
  display: inline-block;
  vertical-align: middle;
}

.tit-cnt-box .tc-title {
  text-align: right;
}

[data-theme='light'] .tit-cnt-box .tc-title {
  color: #18191f;
}

[data-theme='dark'] .tit-cnt-box .tc-title {
  color: #fff;
}

[data-theme='light'] .tit-cnt-box .tc-cnt {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .tit-cnt-box .tc-cnt {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .tit-cnt-box .tc-desc {
  color: #707070;
}

[data-theme='dark'] .tit-cnt-box .tc-desc {
  color: rgba(255,255,255,0.65);
}

em {
  font-style: initial;
  color: red !important;
}

[data-theme='light'] .header-nav {
  background-color: #fff;
}

[data-theme='dark'] .header-nav {
  background-color: #212d40;
}

[data-theme='light'] .header-nav {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .header-nav {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .header-nav {
  border-color: rgba(50,58,70,0.1);
}

[data-theme='dark'] .header-nav {
  border-color: #111c2d;
}

.h5-topnav-box {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  font-size: .16rem;
}

[data-theme='light'] .h5-topnav-box {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .h5-topnav-box {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .h5-topnav-box {
  background-color: #fff;
}

[data-theme='dark'] .h5-topnav-box {
  background-color: #212d40;
}

.h5-topnav-box .topnav-cnt-box {
  margin: 0 auto;
  padding: 0.12rem 0.15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.h5-topnav-box .topnav-cnt-box .left-menu-box {
  width: 25%;
  text-align: left;
}

.h5-topnav-box .topnav-cnt-box .left-menu-box .btn-showMenu {
  font-size: .3rem;
}

[data-theme='light'] .h5-topnav-box .topnav-cnt-box .left-menu-box .btn-showMenu {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .h5-topnav-box .topnav-cnt-box .left-menu-box .btn-showMenu {
  color: rgba(255,255,255,0.85);
}

.h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal {
  height: 100%;
  width: 70%;
}

[data-theme='light'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal {
  color: rgba(255,255,255,0.85);
}

.h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal .top-btn {
  padding: 0.14rem 0 0.1rem 0.14rem;
}

.h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal .top-btn .close-menu-modal {
  font-size: 0.3rem;
}

[data-theme='light'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal .top-btn .close-menu-modal {
  color: #18191f;
}

[data-theme='dark'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal .top-btn .close-menu-modal {
  color: #fff;
}

.h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal ::v-deep .menu-list .van-cell {
  font-size: .16rem;
}

.h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal ::v-deep .menu-list .menu-title {
  font-weight: bold;
}

[data-theme='light'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal ::v-deep .menu-list .menu-title {
  color: #18191f;
}

[data-theme='dark'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal ::v-deep .menu-list .menu-title {
  color: #fff;
}

.h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal ::v-deep .menu-list .menu-cnt {
  font-weight: initial;
}

[data-theme='light'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal ::v-deep .menu-list .menu-cnt {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .h5-topnav-box .topnav-cnt-box .left-menu-box .menu-modal ::v-deep .menu-list .menu-cnt {
  color: rgba(255,255,255,0.85);
}

.h5-topnav-box .topnav-cnt-box .center-logo-box {
  cursor: pointer;
}

.h5-topnav-box .topnav-cnt-box .center-logo-box .icon {
  width: 1.2rem;
  height: 0.44rem;
}

.h5-topnav-box .topnav-cnt-box .right-optlist-box {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.h5-topnav-box .topnav-cnt-box .right-optlist-box .cur-language {
  text-align: right;
}

.h5-topnav-box .topnav-cnt-box .right-optlist-box .cur-language .lang-text {
  display: inline-block;
  vertical-align: middle;
}

.h5-topnav-box .topnav-cnt-box .right-optlist-box .cur-language .drop-icon {
  display: inline-block;
  vertical-align: middle;
}

.h5-topnav-box .topnav-cnt-box .right-optlist-box .cur-language .drop-icon .icon {
  display: inline-block;
  vertical-align: middle;
  width: 0.18rem;
  height: 0.18rem;
}

.h5-topnav-box .topnav-cnt-box .up {
  transform: rotate(180deg);
}

[data-theme='light'] .h5-topnav-box.light {
  color: #fff;
}

[data-theme='dark'] .h5-topnav-box.light {
  color: rgba(255,255,255,0.9);
}

[data-theme='light'] .h5-topnav-box.light {
  background-color: #96f;
}

[data-theme='dark'] .h5-topnav-box.light {
  background-color: #4793cf;
}

[data-theme='light'] .h5-topnav-box.light .topnav-cnt-box .left-menu-box .btn-showMenu {
  color: #fff;
}

[data-theme='dark'] .h5-topnav-box.light .topnav-cnt-box .left-menu-box .btn-showMenu {
  color: rgba(255,255,255,0.9);
}