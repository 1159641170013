:export {
  fs-55: .55rem;
  fs-54: .54rem;
  fs-52: .52rem;
  fs-50: .5rem;
  fs-48: .48rem;
  fs-46: .46rem;
  fs-44: .44rem;
  fs-42: .42rem;
  fs-40: .4rem;
  fs-38: .38rem;
  fs-36: .36rem;
  fs-34: .34rem;
  fs-32: .32rem;
  fs-30: .3rem;
  fs-28: .28rem;
  fs-26: .26rem;
  fs-24: .24rem;
  fs-22: .22rem;
  fs-20: .2rem;
  fs-18: .18rem;
  fs-16: .16rem;
  fs-14: .14rem;
  fs-12: .12rem;
  fs-10: .1rem;
  light-themes1: #96f;
  light-themes2: #00377a;
  light-echarts1: #fe2c55;
  light-echarts2: #60a9ff;
  light-echarts3: #5ec45e;
  light-echarts4: #32b5b5;
  light-echarts5: #faad14;
  light-echarts6: #fe8358;
  light-echarts7: #9760e3;
  light-link: #96f;
  light-tb-head: rgba(61,150,255,0.08);
  light-btn-color: #fff;
  light-source: #6c849c;
  light-border: rgba(50,58,70,0.1);
  light-placeholder: rgba(50,58,70,0.25);
  light-inp-border: #dcdfe6;
  light-th-border: #b9d0ea;
  light-desc: #707070;
  light-color: rgba(24,25,31,0.9);
  light-title: #18191f;
  light-card-bg: #fff;
  light-popup-bg: #fff;
  light-bg: #fff;
  dark-themes1: #4793cf;
  dark-themes2: #7a8a9c;
  dark-echarts1: #fe2c55;
  dark-echarts2: #60a9ff;
  dark-echarts3: #5ec45e;
  dark-echarts4: #32b5b5;
  dark-echarts5: #faad14;
  dark-echarts6: #fe8358;
  dark-echarts7: #9760e3;
  dark-link: #378bee;
  dark-tb-head: rgba(61,150,255,0.08);
  dark-btn-color: rgba(255,255,255,0.9);
  dark-source: #6c849c;
  dark-border: #111c2d;
  dark-placeholder: rgba(255,255,255,0.25);
  dark-inp-border: #142033;
  dark-th-border: rgba(71,147,207,0.25);
  dark-desc: rgba(255,255,255,0.65);
  dark-color: rgba(255,255,255,0.85);
  dark-title: #fff;
  dark-card-bg: #212d40;
  dark-popup-bg: #222f43;
  dark-bg: #111925;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  content: "";
}

.clearfix {
  zoom: 1;
  clear: both;
}

.no-info {
  width: 100%;
  text-align: center;
  font-size: 0.12rem;
  padding: 0.5rem 0;
}

[data-theme='light'] .no-info {
  color: #707070;
}

[data-theme='dark'] .no-info {
  color: rgba(255,255,255,0.65);
}

.tit-cnt-box .tc-title,
.tit-cnt-box .tc-cnt {
  display: inline-block;
  vertical-align: top;
}

.tit-cnt-box .tc-title.center,
.tit-cnt-box .tc-cnt.center {
  display: inline-block;
  vertical-align: middle;
}

.tit-cnt-box .tc-title {
  text-align: right;
}

[data-theme='light'] .tit-cnt-box .tc-title {
  color: #18191f;
}

[data-theme='dark'] .tit-cnt-box .tc-title {
  color: #fff;
}

[data-theme='light'] .tit-cnt-box .tc-cnt {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .tit-cnt-box .tc-cnt {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .tit-cnt-box .tc-desc {
  color: #707070;
}

[data-theme='dark'] .tit-cnt-box .tc-desc {
  color: rgba(255,255,255,0.65);
}

em {
  font-style: initial;
  color: red !important;
}

[data-theme='light'] .header-nav {
  background-color: #fff;
}

[data-theme='dark'] .header-nav {
  background-color: #212d40;
}

[data-theme='light'] .header-nav {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .header-nav {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .header-nav {
  border-color: rgba(50,58,70,0.1);
}

[data-theme='dark'] .header-nav {
  border-color: #111c2d;
}

.page-bottom-box {
  width: 100%;
  padding: 0.24rem 0;
}

[data-theme='light'] .page-bottom-box {
  background-color: rgba(24,25,31,0.9);
}

.page-bottom-box .desc-text {
  width: 100%;
  text-align: center;
  font-size: .14rem;
}

[data-theme='light'] .page-bottom-box .desc-text {
  color: rgba(255,255,255,0.8);
}

.page-bottom-box .media-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 0.2rem;
}

.page-bottom-box .media-list .state-media-item {
  flex: 1;
  margin-right: 0.15rem;
}

.page-bottom-box .media-list .state-media-item:last-child {
  margin-right: 0;
}

.page-bottom-box .media-list .state-media-item img {
  cursor: pointer;
  width: 100%;
}