:export {
  fs-55: .55rem;
  fs-54: .54rem;
  fs-52: .52rem;
  fs-50: .5rem;
  fs-48: .48rem;
  fs-46: .46rem;
  fs-44: .44rem;
  fs-42: .42rem;
  fs-40: .4rem;
  fs-38: .38rem;
  fs-36: .36rem;
  fs-34: .34rem;
  fs-32: .32rem;
  fs-30: .3rem;
  fs-28: .28rem;
  fs-26: .26rem;
  fs-24: .24rem;
  fs-22: .22rem;
  fs-20: .2rem;
  fs-18: .18rem;
  fs-16: .16rem;
  fs-14: .14rem;
  fs-12: .12rem;
  fs-10: .1rem;
  light-themes1: #96f;
  light-themes2: #00377a;
  light-echarts1: #fe2c55;
  light-echarts2: #60a9ff;
  light-echarts3: #5ec45e;
  light-echarts4: #32b5b5;
  light-echarts5: #faad14;
  light-echarts6: #fe8358;
  light-echarts7: #9760e3;
  light-link: #96f;
  light-tb-head: rgba(61,150,255,0.08);
  light-btn-color: #fff;
  light-source: #6c849c;
  light-border: rgba(50,58,70,0.1);
  light-placeholder: rgba(50,58,70,0.25);
  light-inp-border: #dcdfe6;
  light-th-border: #b9d0ea;
  light-desc: #707070;
  light-color: rgba(24,25,31,0.9);
  light-title: #18191f;
  light-card-bg: #fff;
  light-popup-bg: #fff;
  light-bg: #fff;
  dark-themes1: #4793cf;
  dark-themes2: #7a8a9c;
  dark-echarts1: #fe2c55;
  dark-echarts2: #60a9ff;
  dark-echarts3: #5ec45e;
  dark-echarts4: #32b5b5;
  dark-echarts5: #faad14;
  dark-echarts6: #fe8358;
  dark-echarts7: #9760e3;
  dark-link: #378bee;
  dark-tb-head: rgba(61,150,255,0.08);
  dark-btn-color: rgba(255,255,255,0.9);
  dark-source: #6c849c;
  dark-border: #111c2d;
  dark-placeholder: rgba(255,255,255,0.25);
  dark-inp-border: #142033;
  dark-th-border: rgba(71,147,207,0.25);
  dark-desc: rgba(255,255,255,0.65);
  dark-color: rgba(255,255,255,0.85);
  dark-title: #fff;
  dark-card-bg: #212d40;
  dark-popup-bg: #222f43;
  dark-bg: #111925;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  content: "";
}

.clearfix {
  zoom: 1;
  clear: both;
}

.no-info {
  width: 100%;
  text-align: center;
  font-size: 0.12rem;
  padding: 0.5rem 0;
}

[data-theme='light'] .no-info {
  color: #707070;
}

[data-theme='dark'] .no-info {
  color: rgba(255,255,255,0.65);
}

.tit-cnt-box .tc-title,
.tit-cnt-box .tc-cnt {
  display: inline-block;
  vertical-align: top;
}

.tit-cnt-box .tc-title.center,
.tit-cnt-box .tc-cnt.center {
  display: inline-block;
  vertical-align: middle;
}

.tit-cnt-box .tc-title {
  text-align: right;
}

[data-theme='light'] .tit-cnt-box .tc-title {
  color: #18191f;
}

[data-theme='dark'] .tit-cnt-box .tc-title {
  color: #fff;
}

[data-theme='light'] .tit-cnt-box .tc-cnt {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .tit-cnt-box .tc-cnt {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .tit-cnt-box .tc-desc {
  color: #707070;
}

[data-theme='dark'] .tit-cnt-box .tc-desc {
  color: rgba(255,255,255,0.65);
}

em {
  font-style: initial;
  color: red !important;
}

[data-theme='light'] .header-nav {
  background-color: #fff;
}

[data-theme='dark'] .header-nav {
  background-color: #212d40;
}

[data-theme='light'] .header-nav {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .header-nav {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .header-nav {
  border-color: rgba(50,58,70,0.1);
}

[data-theme='dark'] .header-nav {
  border-color: #111c2d;
}

.plus-box {
  width: 100%;
}

.plus-box .plus-container {
  width: 100%;
}

.plus-box .plus-container .section {
  padding-top: 0.8rem;
  width: 100%;
}

[data-theme='light'] .plus-box .plus-container .section {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .plus-box .plus-container .section {
  color: rgba(255,255,255,0.85);
}

[data-theme='light'] .plus-box .plus-container .section {
  background-color: #fff;
}

[data-theme='dark'] .plus-box .plus-container .section {
  background-color: #212d40;
}

.plus-box .plus-container .section .section-cnt-box {
  width: 100%;
  margin: 0 auto;
}

.plus-box .plus-container .section .section-cnt-box .btn-item {
  cursor: pointer;
  font-size: .16rem;
  width: 1.62rem;
  margin: 0.2rem auto;
  text-align: center;
  border-radius: 0.24rem;
  padding: 0.14rem 0;
  font-weight: bold;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .btn-item {
  background-color: #96f;
}

[data-theme='dark'] .plus-box .plus-container .section .section-cnt-box .btn-item {
  background-color: #4793cf;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .btn-item {
  color: #fff;
}

[data-theme='dark'] .plus-box .plus-container .section .section-cnt-box .btn-item {
  color: rgba(255,255,255,0.9);
}

.plus-box .plus-container .section .section-cnt-box .btn-item .btn-icon {
  font-weight: bold;
  margin-left: 0.08rem;
  transform: rotate(-90deg);
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box {
  width: 100%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus {
  margin-top: 0.4rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .text-cnt-box {
  width: 100%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .text-cnt-box .title-box {
  font-size: .48rem;
  width: 58.3%;
  margin-bottom: 0.32rem;
  text-align: center;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .text-cnt-box .text-box {
  width: 58.3%;
  line-height: 0.34rem;
  font-size: .22rem;
  text-align: center;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .img-box {
  width: 52.8%;
  font-size: 0;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .img-box .bn-img {
  width: 100%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .trait-list {
  margin-top: 0.2rem;
  width: 52.8%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .trait-list ul li.trait-item .item-box {
  padding: 0.24rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .trait-list ul li.trait-item .item-box .item-icon {
  width: 0.72rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .trait-list ul li.trait-item .item-box .item-tit-cnt {
  width: 80%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .trait-list ul li.trait-item .item-box .item-tit-cnt .item-title {
  font-size: .26rem;
  margin-bottom: 0.08rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .trait-list ul li.trait-item .item-box .item-tit-cnt .item-cnt {
  font-size: .2rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .more-text {
  width: 52.8%;
  font-size: .2rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box.web-plus .btn-getstart {
  margin-top: 0.4rem;
  margin-bottom: 0.64rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .text-cnt-box {
  width: 100%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .text-cnt-box .title-box {
  font-size: .3rem;
  font-weight: bold;
  width: 83%;
  margin: 0 auto;
  margin-bottom: 0.14rem;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .text-cnt-box .title-box {
  color: #18191f;
}

[data-theme='dark'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .text-cnt-box .title-box {
  color: #fff;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .text-cnt-box .text-box {
  font-size: .16rem;
  line-height: 0.24rem;
  width: 83%;
  margin: 0 auto;
  margin-bottom: 0.4rem;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .text-cnt-box .text-box {
  color: rgba(24,25,31,0.9);
}

[data-theme='dark'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .text-cnt-box .text-box {
  color: rgba(255,255,255,0.85);
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .img-box {
  width: 100%;
  margin: 0 auto;
  font-size: 0;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .img-box .bn-img {
  width: 100%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list {
  width: 83%;
  margin: 0 auto;
  margin-bottom: 0.4rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul {
  width: 100%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item {
  width: 100%;
  margin-bottom: 0.12rem;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box {
  padding: 0.16rem;
  display: flex;
  align-items: center;
  border-radius: 0.16rem;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box {
  background-color: #f6f8fa;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-icon {
  margin-right: 0.12rem;
  width: 0.48rem;
  font-size: 0;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-icon img {
  width: 100%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-tit-cnt {
  width: 80%;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-tit-cnt .item-title {
  font-size: .16rem;
  margin-bottom: 0.04rem;
  font-weight: bold;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-tit-cnt .item-title {
  color: #18191f;
}

[data-theme='dark'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-tit-cnt .item-title {
  color: #fff;
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-tit-cnt .item-cnt {
  font-size: .12rem;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-tit-cnt .item-cnt {
  color: #707070;
}

[data-theme='dark'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .trait-list ul li.trait-item .item-box .item-tit-cnt .item-cnt {
  color: rgba(255,255,255,0.65);
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .more-text {
  width: 83%;
  margin: 0 auto;
  text-align: center;
  font-size: .14rem;
}

[data-theme='light'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .more-text {
  color: #707070;
}

[data-theme='dark'] .plus-box .plus-container .section .section-cnt-box .img-cnt-box .more-text {
  color: rgba(255,255,255,0.65);
}

.plus-box .plus-container .section .section-cnt-box .img-cnt-box .btn-getstart {
  width: 1.92rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}